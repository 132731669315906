export const PARAM = {
  EMAIL: ':email',
  YAML_QUESTION_ID: ':yaml_question_id',
  USER_ID: ':user_id',
  ORG_PRODUCT_ID: ':org_product_id',
  ANSWER_ID: ':answer_id',
  ASSESSOR_ID: ':assessor_id',
};

export const API = {
  PREFIX: '/api',
  HEALTH_CHECK_LIVENESS: '/health/liveness',
  HEALTH_CHECK_READINESS: '/health/readiness',
  USER_POST: '/user',
  USER_GET: `/user/${PARAM.EMAIL}`,
  ORG_PRODUCT_GET: '/org_product',
  QUESTION_POST: '/question',
  QUESTION_GET: `/question/${PARAM.YAML_QUESTION_ID}`,
  ANSWER_POST: '/answer',
  ANSWER_GET: `/answer/all`,
};

export const HTTP_OK_CODE = 200;
export const BAD_REQUEST_CODE = 400;
export const UNAUTHORIZED_ERROR_CODE = 401;
export const NOT_FOUND_ERROR_CODE = 404;
export const UNEXPECTED_ERROR_CODE = 500;
